<template>
  <div class="senior-setup">
    <el-form label-position="top" label-width="80px">
      <el-form-item :label="$t('proSetting.signMessage')">
        <el-switch :inactive-text="$t('proSetting.inactive')" :active-text="$t('proSetting.active')" v-model="setup.sign"></el-switch>
        <div class="sign-tip">{{$t('proSetting.signTip')}}</div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'FormProSetting',
  computed: {
    setup() {
      return this.$store.state.design.settings
    }
  },
  data() {
    return {}
  },
  methods: {
    validate() {
      return []
    }
  }
}
</script>

<style lang="scss" scoped>
.senior-setup {
  overflow: auto;
  width: 600px;
  height: calc(100vh - 105px);
  background: #ffffff;
  margin: 10px auto 0;
  padding: 15px 20px;

  .sign-tip {
    color: #949495;
    font-size: small;
    margin-left: 20px;
  }
}
</style>
