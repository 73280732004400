<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input size="small" v-model="value.placeholder" :placeholder="$t('formConfig.date.dateTimeTips')"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.date.dateTimeFormatLabel')">
      <el-select size="small" v-model="value.format">
        <el-option value="yyyy" :label="$t('formConfig.date.yyyy')"></el-option>
        <el-option value="yyyy-MM" :label="$t('formConfig.date.yyyyMM')"></el-option>
        <el-option value="yyyy-MM-dd" :label="$t('formConfig.date.yyyyMMdd')"></el-option>
        <el-option value="yyyy-MM-dd HH:mm" :label="$t('formConfig.date.yyyyMMddHHmm')"></el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "DateTime",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
