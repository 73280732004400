<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input size="small" v-model="value.placeholder" :placeholder="$t('formConfig.placeholderValue')"  maxlength="15"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.multipleLabel')">
      <el-switch v-model="value.multiple"></el-switch>
    </el-form-item>
  </div>

</template>

<script>
export default {
  name: "OrgPicker",
  components: {},
  props:{
    value:{
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
