<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input size="small" v-model="value.placeholder" :placeholder="$t('formConfig.placeholderValue')"  maxlength="15"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.decimal')">
      <el-input-number controls-position="right" :precision="0" :max="4" :min="0" size="small" v-model="value.precision"/>
    </el-form-item>
    <el-form-item v-show="$i18n.locale==='zh'" :label="$t('formConfig.showChinese')">
      <el-switch v-model="value.showChinese"></el-switch>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: "AmountInputConfig",
  components: {},
  props:{
    value:{
      type: Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
