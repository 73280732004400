<template>
  <div class="layout-bg">
    <div class="layout-header">
      <el-menu :default-active="activeMenu" active-text-color="#409eff" mode="horizontal">
        <el-menu-item index="baseSetting" @click="changeMenu('baseSetting')">
          {{ '①' + $t('FormProcessDesign.title.baseSetting') }}
        </el-menu-item>
        <el-menu-item index="formSetting" @click="changeMenu('formSetting')">
          {{ '②' + $t('FormProcessDesign.title.formSetting') }}
        </el-menu-item>
        <el-menu-item index="processDesign" @click="changeMenu('processDesign')">
          {{ '③' + $t('FormProcessDesign.title.processDesign') }}
        </el-menu-item>
        <el-menu-item index="proSetting" @click="changeMenu('proSetting')">
          {{ '④' + $t('FormProcessDesign.title.proSetting') }}
        </el-menu-item>
      </el-menu>
      <div class="publish">
        <!--        <el-button size="mini" @click="preview"><i class="el-icon-view"></i>预览</el-button>-->
        <el-button size="mini" type="primary" @click="publish"><i
            class="el-icon-s-promotion"></i>{{ $t('FormProcessDesign.publish') }}
        </el-button>
      </div>
      <div class="back">
        <el-button @click="exit" size="medium" icon="el-icon-arrow-left" circle></el-button>
        <span>
            <i :class="setup.logo.icon" :style="'background:' + setup.logo.background"></i>
            <span>{{ setup.formName }}</span>
        </span>
      </div>
    </div>
    <div class="layout-body">
      <baseSetting ref="baseSetting" v-show="activeMenu === 'baseSetting'" @next="changeMenu('formSetting')"/>
      <form-design ref="formSetting" v-show="activeMenu === 'formSetting'" @next="changeMenu('processDesign')"/>
      <process-design ref="processDesign" v-show="activeMenu === 'processDesign'"/>
      <proSetting ref="proSetting" v-show="activeMenu === 'proSetting'"/>
    </div>
    <w-dialog :showFooter="false" v-model="validVisible" :title="$t('FormProcessDesign.popTips')">
      <el-steps align-center :active="validStep" finish-status="success">
        <el-step v-for="(step, i) in validOptions" :title="step.title" :key="i"
                 :icon="step.icon" :status="step.status" :description="step.description"
        />
      </el-steps>
      <el-result :icon="validIcon" :title="errTitle" :subTitle="validResult.desc">
        <i slot="icon" style="font-size: 30px" v-if="!validResult.finished" class="el-icon-loading"></i>
        <div slot="subTitle" class="err-info" v-if="validResult.errs.length > 0">
          <ellipsis hover-tip v-for="(err, i) in validResult.errs" :key="i + '_err'" :content="err">
            <i slot="pre" class="el-icon-warning-outline"></i>
          </ellipsis>
        </div>
        <template slot="extra">
          <el-button type="primary" v-if="validResult.finished" size="medium" @click="doAfter">
            {{ validResult.action }}
          </el-button>
        </template>
      </el-result>
    </w-dialog>
    <!--    <el-dialog title="请使用手机扫码预览" :visible.sync="viewCode" width="300px" :close-on-click-modal="false" center>-->
    <!--      <img src="@/assets/image/code.png" width="250" height="250">-->
    <!--    </el-dialog>-->
  </div>
</template>
<script>
import {getFormDetail, createForm, updateFormDetail} from '@/api/process'
import baseSetting from '@/views/admin/layout/FormBaseSetting'
import formDesign from '@/views/admin/layout/FormDesign'
import processDesign from '@/views/admin/layout/ProcessDesign'
import proSetting from '@/views/admin/layout/FormProSetting'
import WDialog from '@/components/common/WDialog'
import Ellipsis from '@/components/common/Ellipsis'

export default {
  name: 'FormProcessDesign',
  components: {baseSetting, formDesign, processDesign, proSetting, WDialog, Ellipsis},
  data() {
    let validOptions = [];
    validOptions.push({title: this.$t('FormProcessDesign.title.baseSetting'), description: '', icon: '', status: ''})
    validOptions.push({title: this.$t('FormProcessDesign.title.formSetting'), description: '', icon: '', status: ''})
    validOptions.push({title: this.$t('FormProcessDesign.title.processDesign'), description: '', icon: '', status: ''})
    validOptions.push({title: this.$t('FormProcessDesign.title.proSetting'), description: '', icon: '', status: ''})
    return {
      activeMenu: 'baseSetting',
      isNew: true,
      timer: null,
      validStep: 0,
      validVisible: false,
      viewCode: false,
      validResult: {},
      validOptions: validOptions,
      validComponents: ['baseSetting', 'formSetting', 'processDesign', 'proSetting'],
      loadGroup: '0'
    }
  },
  computed: {
    setup() {
      return this.$store.state.design
    },
    errTitle() {
      if (this.validResult.finished && !this.validResult.success) {
        return this.validResult.title + ` (${this.validResult.errs.length + this.$t('FormProcessDesign.validResultErr')})`
      }
      return this.validResult.title
    },
    validIcon() {
      if (!this.validResult.finished) {
        return 'el-icon-loading'
      } else if (this.validResult.success) {
        return 'success'
      } else {
        return 'warning'
      }
    }
  },
  created() {
    this.showValidIng()
    let setupStr = localStorage.getItem('setup')
    this.loadGroup = localStorage.getItem('edit_groupId')
    //判断传参，决定是新建还是加载原始数据
    let formId = this.$route.query.code
    let isFormData = formId !== null && formId !== undefined && formId !== '0'
    let initProcess = 'false'
    if (setupStr) {
      this.isNew = !isFormData
      let initForm = JSON.parse(setupStr)
      this.$store.commit('LOAD_FORM', initForm)
    } else {
      if (isFormData) {
        this.isNew = false
        this.loadFormInfo(formId)
      } else {
        this.loadInitForm()
        initProcess = 'true'
      }
    }
    localStorage.setItem('initProcess', initProcess)
    window.addEventListener('beforeunload', this.onPageRefresh)
  },
  beforeDestroy() {
    this.stopTimer()
    localStorage.removeItem('setup')
    localStorage.setItem("taskCount", "1");
    window.removeEventListener('beforeunload', this.onPageRefresh)
  },
  methods: {
    onPageRefresh() {
      //刷新-保存修改数据
      localStorage.setItem('setup', JSON.stringify(this.setup))
    },
    changeMenu(menuPath) {
      this.activeMenu = menuPath
    },
    preview() {
      this.validateDesign()
      setTimeout(() => {
        if (this.validResult.success) {
          this.viewCode = true
        }
      }, 1800)
    },
    publish() {
      this.validateDesign()
    },
    exit() {
      const tips = this.$t('common.popTips')
      const message = this.$t('FormProcessDesign.exitMsg')
      this.$confirm(message, tips, {
        confirmButtonText: this.$t('common.exit'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        localStorage.setItem("taskCount", "1");
        this.$router.push("/formsPanel")
      })
    },
    getRandomId() {
      return `node_${new Date().getTime().toString().substring(5)}${Math.round(Math.random() * 9000 + 1000)}`
    },
    validateDesign() {
      this.validStep = 0
      this.validVisible = true
      this.showValidIng()
      this.stopTimer()
      this.timer = setInterval(() => {
        this.validResult.errs = this.$refs[this.validComponents[this.validStep]].validate()
        if (Array.isArray(this.validResult.errs) && this.validResult.errs.length === 0) {
          this.validStep++
          if (this.validStep >= this.validOptions.length) {
            this.stopTimer()
            this.showValidFinish(true)
          }
        } else {
          this.stopTimer()
          this.validOptions[this.validStep].status = 'error'
          this.showValidFinish(false, this.getDefaultValidErr())
        }
      }, 300)
    },
    loadInitForm() {
      let initForm = {
        formId: null,
        formName: this.$t('process.defaultFormName'),
        logo: {
          icon: 'icon-iconfontkefu',
          background: '#409eff'
        },
        settings: {
          commiter: [],
          admin: [],
          sign: false,
        },
        groupId: undefined,
        formItems: [],
        process: {
          id: 'root',
          parentId: null,
          type: 'ROOT',
          name: this.$t('process.rootName'),
          desc: this.$t('process.rootDesc'),
          props: {
            assignedUser: [],
            formPerms: []
          },
          children: {
            id: this.getRandomId(),
            parentId: 'root',
            name: this.$t('process.taskName'),
            props: {
              userStatistics:false,
              faceVerification:false,
              listing:false,
              broadcast:false,
              taskDelay:false,
              delayTime:'',
              taskDesc: '',
              assignedType: 'SELF_SELECT',
              assignedUser: [],
              formPerms: [],
              formUser: '',
              leader: {level: 1},
              leaderTop: {endCondition: 'TOP', endLevel: 1},
              mode: 'AND',
              nobody: {assignedUser: [], handler: 'TO_PASS'},
              refuse: {target: '', type: 'TO_END'},
              role: [],
              selfSelect: {multiple: false},
              assignee: false,
              uploadType: [],
              perm: 'all',
              comment: false,
              sign: false,
              timeLimit: {
                handler: {type: 'REFUSE', notify: {hour: 1, once: true}},
                timeout: {unit: 'H', value: 0}
              }
            },
            type: 'APPROVAL'
          }
        },
        remark: this.$t('process.remark')
      }
      initForm.groupId = (this.loadGroup !== null && this.loadGroup !== undefined && this.loadGroup !== '0') ? parseInt(this.loadGroup) : null
      this.$store.commit('LOAD_FORM', initForm)
      localStorage.setItem("taskCount", "1");
    },
    loadFormInfo(formId) {
      getFormDetail(formId).then(resp => {
        let form = resp.data
        form.logo = JSON.parse(form.logo)
        form.settings = JSON.parse(form.settings)
        form.formItems = JSON.parse(form.formItems)
        form.process = JSON.parse(form.process)
        this.$store.commit('LOAD_FORM', form)
        let list = []
        this.getTaskCount(form.process, list);
        if (list.length > 0) {
          let max = eval("Math.max(" + list + ")");
          localStorage.setItem("taskCount", (max + 1));
        } else {
          localStorage.setItem("taskCount", "1");
        }
      })
    },
    getTaskCount(process, list) {
      if (process !== undefined) {
        if (process.type === 'APPROVAL') {
          let name = process.name
          if (name.length > 2 && name.substr(0, 2) === '任务') {
            let str = name.substr(2, name.length);
            if (Number(str).toString() !== 'NaN') {
              list.push(Number(str))
            }
          }
          if (name.length > 4 && name.substr(0, 4) === 'Task') {
            let str = name.substr(4, name.length);
            if (Number(str).toString() !== 'NaN') {
              list.push(Number(str))
            }
          } else {
            this.getTaskCount(process.children, list)
          }
        }
        this.getTaskCount(process.children, list)
      }
    },
    showValidIng() {
      this.validResult = {
        errs: [],
        finished: false,
        success: false,
        title: this.$t('FormProcessDesign.validResult.title'),
        action: this.$t('FormProcessDesign.validResult.action'),
        desc: this.$t('FormProcessDesign.validResult.desc')
      }
      this.validStep = 0
      this.validOptions.forEach(item => {
        item.status = ''
        item.icon = ''
        item.description = ''
      })
    },
    showValidFinish(success, err) {
      this.validResult.success = success
      this.validResult.finished = true
      this.validResult.title = this.$t(success ? 'FormProcessDesign.validResult.checkSuccess' : 'FormProcessDesign.validResult.checkFail')
      this.validResult.desc = this.$t(success ? 'FormProcessDesign.validResult.checkDesc' : err)
      this.validResult.action = this.$t(success ? 'FormProcessDesign.validResult.submit' : 'FormProcessDesign.validResult.modify');
    },
    getDefaultValidErr() {
      switch (this.validStep) {
        case 0:
          return this.$t('FormProcessDesign.defaultValidErr.a')
        case 1:
          return this.$t('FormProcessDesign.defaultValidErr.b')
        case 2:
          return this.$t('FormProcessDesign.defaultValidErr.c')
        case 3:
          return this.$t('FormProcessDesign.defaultValidErr.d')
        default:
          return this.$t('FormProcessDesign.defaultValidErr.default')
      }
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    doAfter() {
      if (this.validResult.success) {
        this.doPublish()
      } else {
        this.activeMenu = this.validComponents[this.validStep]
        this.validVisible = false
      }
    },
    doPublish() {
      const tips = this.$t('common.popTips')
      const message = this.$t('FormProcessDesign.publishMsg')
      this.$confirm(message, tips, {
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        let processNew = JSON.parse(JSON.stringify(this.setup.process))
        this.conditionRecursion(processNew)
        let template = {
          formId: this.setup.formId,
          formName: this.setup.formName,
          logo: JSON.stringify(this.setup.logo),
          settings: JSON.stringify(this.setup.settings),
          groupId: this.setup.groupId,
          formItems: JSON.stringify(this.setup.formItems),
          process: JSON.stringify(processNew),
          remark: this.setup.remark
        }
        if (this.isNew || !this.$isNotEmpty(this.setup.formId)) {
          createForm(template).then(resp => {
            this.$message.success(this.$t("tips.addSuccess"))
            localStorage.removeItem('setup')
            this.$router.push('/formsPanel')
          })
        } else {
          updateFormDetail(template).then(resp => {
            this.$message.success(this.$t("tips.editSuccess"))
            localStorage.removeItem('setup')
            this.$router.push('/formsPanel')
          })
        }
      })
    },
    conditionRecursion(process) {
      if (null != process) {
        if (null != process.branchs) {
          process.branchs.map((item, i) => {
            if (i === process.branchs.length - 1) {
              item.typeElse = true
            } else {
              item.typeElse = false
            }
            if (null != item.children) {
              this.conditionRecursion(item.children)
            } else {
              return item
            }
          })
        } else {
          this.conditionRecursion(process.children)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.layout-bg {
  height: 100vh;
  background: #f5f6f6;
}

.layout-header {
  min-width: 980px;
  position: relative;

  .el-menu {
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .publish {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1000;

    i {
      margin-right: 6px;
    }

    button {
      border-radius: 15px;
    }
  }

  .back {
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: 20px;
    font-size: small;

    span {
      i {
        border-radius: 10px;
        padding: 7.8px;
        font-size: 20px;
        color: #ffffff;
        margin: 0 10px;
      }
    }
  }
}

.layout-body {
  min-width: 980px;
}

.err-info {
  max-height: 180px;
  overflow-y: auto;

  & > div {
    padding: 5px;
    margin: 2px 0;
    width: 220px;
    text-align: left;
    border-radius: 3px;
    background: rgb(242 242 242);
  }

  i {
    margin: 0 5px;
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}
</style>
