<template>
  <div class="base-setup" @click="showIconSelect = false">
    <el-form ref="baseSetting" :model="setup" :rules="rules" label-position="top" label-width="80px">
      <el-form-item :label="$t('baseSetting.iconTitle')">
        <i :class="setup.logo.icon" :style="'background:' + setup.logo.background+';cursor:auto'"></i>
        <span class="change-icon">
					<span>
						<span>{{ $t('baseSetting.background') }}</span>
						<el-color-picker v-model="setup.logo.background" show-alpha size="small"
                             :predefine="colors"
            ></el-color-picker>
					</span>
					<span>
						<span>{{ $t('baseSetting.icon') }}</span>
						<el-popover placement="bottom-start" width="390" trigger="click">
							<div class="icon-select">
								<i :class="i" v-for="(i, id) in icons" :key="id" @click="setup.logo.icon = i"></i>
							</div>
              <i slot="reference" :class="setup.logo.icon"></i>
            </el-popover>
					</span>
				</span>
      </el-form-item>
      <el-form-item :label="$t('baseSetting.formName')" prop="formName">
        <el-input v-model="setup.formName" size="medium" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item :label="$t('baseSetting.remark')" prop="remark">
        <el-input v-model="setup.remark" type="textarea" show-word-limit
                  :autosize="{ minRows: 2, maxRows: 5}" maxlength="120"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('baseSetting.group')" class="group" prop="groupId">
        <el-select v-model="setup.groupId" size="medium">
          <el-option v-for="(item, index) in fromGroup" :key="index" v-show="item.id > 1"
                     :label="item.name" :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="el-login-footer">
        <el-button type="primary" @click="next" round>{{$t('process.next')}}</el-button>
      </div>
    </el-form>
    <org-picker :title="title" multiple ref="orgPicker" :selected="select" @ok="selected" disable="dept"></org-picker>
  </div>
</template>
<script>
import OrgPicker from '@/components/common/OrgPicker'
import {getFormGroups} from '@/api/group'
import iconfont from '@/assets/iconfont/iconfont.json'

export default {
  name: 'FormBaseSetting',
  components: {OrgPicker},
  data() {
    const ruleFormName = (rule, value, callback) => {
      if (this.setup.formName === '' || this.setup.formName === undefined) {
        callback(new Error(this.$t('baseSetting.ruleFormName01').toString()))
      } else if (this.setup.formName.length < 1 || this.setup.formName > 30) {
        callback(new Error(this.$t('baseSetting.ruleFormName02').toString()))
      } else {
        callback()
      }
    }
    const ruleFormRemark = (rule, value, callback) => {
      if (this.setup.remark === '' || this.setup.remark === undefined) {
        callback(new Error(this.$t('baseSetting.ruleFormRemark').toString()))
      } else {
        callback()
      }
    }
    const ruleFormGroup = (rule, value, callback) => {
      if (this.setup.groupId === '' || this.setup.groupId === undefined) {
        callback(new Error(this.$t('baseSetting.ruleFormGroup').toString()))
      } else {
        callback()
      }
    }
    return {
      nowUserSelect: null,
      showIconSelect: false,
      select: [],
      fromGroup: [],
      title: '',
      submitRange: [{label: '全员', value: 0}, {label: '指定人员', value: 1}],
      rules: {
        formName: [
          {required: true, trigger: 'blur', validator: ruleFormName},
        ],
        remark: [
          {required: true, validator: ruleFormRemark, trigger: 'blur'}
        ],
        groupId: [
          {required: true, validator: ruleFormGroup, trigger: 'change'}
        ]
      },
      colors: [
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgba(255, 69, 0, 0.68)',
        'rgb(255, 120, 0)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      icons: [
        // 'el-icon-delete-solid',
        // 'el-icon-s-tools',
        // 'el-icon-s-goods',
        // 'el-icon-warning',
        // 'el-icon-circle-plus',
        // 'el-icon-camera-solid',
        // 'el-icon-s-promotion',
        // 'el-icon-s-cooperation',
        // 'el-icon-s-platform',
        // 'el-icon-s-custom',
        // 'el-icon-s-data',
        // 'el-icon-s-check',
        // 'el-icon-s-claim'
      ]
    }
  },
  computed: {
    setup() {
      return this.$store.state.design;
    }
  },
  created() {
    this.loadIconfont()
  },
  mounted() {
    this.getGroups()
  },
  methods: {
    next() {
      this.$emit('next');
    },
    loadIconfont() {
      //TODO 扩展表单图标
      if (iconfont && iconfont.id) {
        iconfont.glyphs.forEach(icon => {
          this.icons.push(`${iconfont.font_family} ${iconfont.css_prefix_text}${icon.font_class}`)
        })
      }
    },
    getGroups() {
      getFormGroups().then(resp => {
        this.fromGroup = resp.data
      })
    },
    selected(select) {
      this.$set(this.setup.settings, this.nowUserSelect, select)
    },
    changeRange() {
      if (this.setup.settings.submitRange === 0) {
        this.setup.settings['commiter'].length = 0
      }
    },
    selectOrgUser(key, title) {
      this.select = this.setup.settings[key]
      this.nowUserSelect = key
      this.title = title
      this.$refs.orgPicker.show()
    },
    removeTag(type, index) {
      let tagArray = this.setup.settings[type]
      tagArray.splice(index, 1)
    },
    validate() {
      this.$refs.baseSetting.validate()
      let err = []
      if (!this.$isNotEmpty(this.setup.formName)) {
        err.push(this.$t('baseSetting.errorTips.name'))
      }
      if (!this.$isNotEmpty(this.setup.groupId)) {
        err.push(this.$t('baseSetting.errorTips.group'))
      }
      return err
    }
  }
}
</script>

<style lang="scss" scoped>
.el-login-footer {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.el-select-dropdown {
  display: none;
}

.icon-select {
  display: flex;
  flex-wrap: wrap;

  i {
    cursor: pointer;
    font-size: large;
    padding: 10px;
    max-width: 38px !important;

    &:hover {
      box-shadow: 0 0 10px 2px #C2C2C2;
    }
  }
}

.select-u {
  width: 100%;
}

.base-setup {
  overflow: auto;
  width: 600px;
  height: calc(100vh - 105px);
  background: #ffffff;
  margin: 10px auto 0;
  padding: 15px 20px;

  i:first-child {
    position: relative;
    cursor: pointer;
    font-size: xx-large;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px;
  }

  .change-icon {
    margin-left: 20px;

    span {
      font-size: small;
      color: #7a7a7a;
      margin-right: 15px;
    }

    i {
      cursor: pointer;
      color: #7a7a7a;
      font-size: x-large;
    }
  }

  .el-form-item__label {
    padding: 0;
    font-weight: bold;
  }

  .el-form-item {
    margin-bottom: 5px;
  }
}

.group {
  .el-select {
    width: calc(100%);
  }

  .el-button {
    margin-left: 10px;
    width: 120px;
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #e8e8e8;
}
</style>
