<template>
  <div>
    <el-form inline label-width="100px">
      <el-form-item :label="$t('nodeConfig.condition.levelLabel')" prop="level">
        <el-popover placement="right" :title="$t('nodeConfig.condition.modifyLevelLabel')" width="250" trigger="click">
          <draggable style="width: 100%; min-height:25px" :list="prioritySortList" group="from" :options="sortOption">
            <template v-for="(cd, index) in prioritySortList">
              <div v-if="index < (prioritySortList.length-1)"
                   :class="{'drag-no-choose': true, 'drag-hover': cd.id === selectedNode.id}">
                <ellipsis style="width: 160px;" hover-tip :content="cd.name"/>
                <div> {{ $t('nodeConfig.condition.priority') + (index + 1) }}</div>
              </div>
            </template>
          </draggable>
          <el-button icon="el-icon-sort" size="small" slot="reference">
            {{ $t('nodeConfig.condition.level', {nowNodeLeave: (nowNodeLeave + 1)}) }}
          </el-button>
        </el-popover>
      </el-form-item>
      <el-form-item :label="$t('nodeConfig.condition.groupsLabel')" label-width="150px">
        <el-switch v-model="config.groupsType" active-color="#409EFF"
                   inactive-color="#c1c1c1" active-value="AND" inactive-value="OR"
                   :active-text="$t('nodeConfig.condition.and')"
                   :inactive-text="$t('nodeConfig.condition.or')"
        >
        </el-switch>
      </el-form-item>
      <!--      <el-form-item label="条件组表达式">-->
      <!--        <el-input size="mini" v-model="config.expression" placeholder="输入条件组关系表达式  &为与，|为或"/>-->
      <!--        <span class="item-desc">使用表达式构建复杂逻辑，例如: (A & B) | C</span>-->
      <!--      </el-form-item>-->
    </el-form>
    <div>
      <el-button type="primary" size="mini" icon="el-icon-plus" style="margin: 0 15px 15px 0" round
                 @click="addConditionGroup"
      >
        {{ $t('nodeConfig.condition.addGroup') }}
      </el-button>
      <span class="item-desc">{{ $t('nodeConfig.condition.addGroupDesc') }}</span>
    </div>
    <group-item/>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import GroupItem from './ConditionGroupItemConfig.vue'
import Ellipsis from '@/components/common/Ellipsis'

export default {
  name: 'ConditionNodeConfig',
  components: {draggable, GroupItem, Ellipsis},
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.selectedNode
    },
    select() {
      return this.config.assignedUser || []
    },
    nowNodeLeave() {
      return this.prioritySortList.indexOf(this.selectedNode)
    },
    //条件节点
    prioritySortList() {
      let node = this.$store.state.nodeMap.get(this.selectedNode.parentId)
      console.log(this.selectedNode.id, node)
      if (node) {
        return node.branchs || []
      }
      return []
    }
  },
  data() {
    return {
      sortOption: {
        animation: 300,
        chosenClass: 'choose',
        scroll: true,
        sort: true
      }
    }
  },
  methods: {
    addConditionGroup() {
      if (this.config.groups.length >= 10) {
        this.$message.warning(this.$t('nodeConfig.condition.maxLimit'))
        return
      }
      this.config.groups.push({
        cids: [],
        groupType: 'OR',
        conditions: []
      })
    },
    selectUser() {
      this.showOrgSelect = true
    },
    selected(select) {
      console.log(select)
      this.showOrgSelect = false
      select.forEach(val => this.select.push(val))
    },
    removeOrgItem(index) {
      this.select.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.choose {
  border-radius: 5px;
  margin-top: 2px;
  background: #f4f4f4;
  border: 1px dashed #1890FF !important;
}

.drag-hover {
  color: #1890FF
}

.drag-no-choose {
  cursor: move;
  background: #f8f8f8;
  border-radius: 5px;
  margin: 5px 0;
  height: 25px;
  line-height: 25px;
  padding: 5px 10px;
  border: 1px solid #ffffff;

  div {
    display: inline-block;
    font-size: small !important;
  }

  div:nth-child(2) {
    float: right !important;
  }
}
</style>
