<template>
  <node :title="$t('node.rootNode.title')" :is-root="true" :content="content"
        @selected="$emit('selected')" @insertNode="type => $emit('insertNode', type)"
        :placeholder="$t('node.rootNode.placeholder')" :header-bgc="headerBgc" header-icon="el-icon-user-solid"/>
</template>

<script>
import Node from './Node'

export default {
  name: "RootNode",
  components: {Node},
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    content() {
      if (this.config.props.assignedUser.length > 0) {
        let texts = []
        this.config.props.assignedUser.forEach(org => texts.push(org.name))
        return String(texts).replaceAll(',', '、')
      } else {
        return this.$t('node.rootNode.defaultContent')
      }
    },
    headerBgc() {
      if (this.$store.state.diagramMode === 'viewer') {
        return this.config.props.headerBgc
      } else {
        return '#576a95'
      }
    },
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>

</style>
