<template>
  <node :title="config.name" :show-error="showError" :content="content" :error-info="errorInfo"
        @selected="$emit('selected')" @delNode="$emit('delNode')" @insertNode="type => $emit('insertNode', type)"
        :placeholder="$t('node.approvalNode.placeholder')" :header-bgc="headerBgc" header-icon="el-icon-s-check"
  />
</template>

<script>
import Node from './Node'

export default {
  name: 'ApprovalNode',
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {Node},
  data() {
    return {
      showError: false,
      errorInfo: ''
    }
  },
  computed: {
    headerBgc() {
      if (this.$store.state.diagramMode === 'viewer') {
        return this.config.props.headerBgc
      } else {
        return '#ff943e'
      }
    },
    content() {
      const config = this.config.props
      switch (config.assignedType) {
        case 'ASSIGN_USER':
          if (config.assignedUser.length > 0) {
            let texts = []
            config.assignedUser.forEach(org => texts.push(org.name))
            return String(texts).replaceAll(',', '、')
          } else {
            return this.$t('node.approvalNode.assignUserDefault')
          }
        case 'SELF':
          return this.$t('node.approvalNode.selfDefault')
        case 'SELF_SELECT':
          return config.selfSelect.multiple ? this.$t('node.approvalNode.selfSelect.more')
              : this.$t('node.approvalNode.selfSelect.one')
          // case 'LEADER_TOP':
          //   return '多级主管依次审批'
          // case 'LEADER':
          //   return config.leader.level > 1 ? '发起人的第 ' + config.leader.level + ' 级主管' : '发起人的直接主管'
        case 'FORM_USER':
          if (!config.formUser || config.formUser === '') {
            return this.$t('node.approvalNode.formUser.noSelect')
          } else {
            let text = this.getFormItemById(config.formUser)
            if (text && text.title) {
              return this.$t('node.approvalNode.formUser.select', {title: text.title})
            } else {
              return this.$t('node.approvalNode.formUser.remove')
            }
          }
          // case 'ROLE':
          //   if (config.role.length > 0) {
          //     return String(config.role).replaceAll(',', '、')
          //   } else {
          //     return '指定角色（未设置）'
          //   }
        default:
          return this.$t('node.approvalNode.default')
      }
    }
  },
  methods: {
    getFormItemById(id) {
      return this.$store.state.design.formItems.find(item => item.id === id)
    },
    //校验数据配置的合法性
    validate(err) {
      try {
        this.showError = !this[`validate_${this.config.props.assignedType}`](err)
        if (!this.$isNotEmpty(this.$store.state.selectedNode.name)) {
          err.push(this.$t('node.approvalNode.validate.validateName'))
          this.showError = true
        }
        if (this.config.props.nobody.handler === 'TO_USER' && this.config.props.nobody.assignedUser.length === 0) {
          this.errorInfo = this.$t('node.approvalNode.validate.validateToUser')
          err.push(this.$t('node.approvalNode.validate.validateToUser'))
          this.showError = true
        }
        return this.showError
      } catch (e) {
        return true
      }
    },
    validate_ASSIGN_USER(err) {
      if (this.config.props.assignedUser.length > 0) {
        return true
      } else {
        this.errorInfo = this.$t('node.approvalNode.validate.validateAssignUser', {name: ''})
        err.push(this.$t('node.approvalNode.validate.validateAssignUser', {name: this.config.name}))
        return false
      }
    },
    validate_SELF_SELECT(err) {
      return true
    },
    validate_LEADER_TOP(err) {
      return true
    },
    validate_LEADER(err) {
      return true
    },
    // validate_ROLE(err) {
    //   if (this.config.props.role.length <= 0) {
    //     this.errorInfo = '请指定负责审批的系统角色'
    //     err.push(`${this.config.name} 未指定审批角色`)
    //     return false
    //   }
    //   return true
    // },
    validate_SELF(err) {
      return true
    },
    validate_FORM_USER(err) {
      if (this.config.props.formUser === '') {
        this.errorInfo = this.$t('node.approvalNode.validate.validateFormUser', {name: ''})
        err.push(this.$t('node.approvalNode.validate.validateFormUser', {name: this.config.name}))
        return false
      }
      return true
    },
    validate_REFUSE(err) {
      return true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
