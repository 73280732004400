<template>
  <div class="container">
    <div class="left-board">
      <div class="logo-wrapper">
        <div class="logo">
          <img :src="logo" alt="logo"> {{$t('formDesign.title')}}
        </div>
      </div>
      <el-scrollbar class="left-scrollbar">
        <div class="components-list">
          <div class="components-title" v-for="(group, index) in baseComponents" :key="index">
            <p>{{ group.name }}</p>
            <draggable class="components-draggable" :list="group.components" :options="{sort: false}"
                       :group="{ name: 'form', pull: 'clone', put: false }"
                       @start="isStart = true" @end="isStart = false" :clone="clone"
            >
              <div v-for="(element, index) in group.components" :key="index" class="components-item">
                <div class="components-body">
                  <i :class="element.icon"></i>
                  {{ element.title }}
                </div>
              </div>
            </draggable>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="center-board">
      <div class="action-bar">
        <el-tooltip class="item" effect="dark" :content="$t('formDesign.preview')" placement="bottom-start">
          <el-button icon="el-icon-view" type="text" @click="viewForms"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('formDesign.mobile')" placement="bottom-start">
          <el-button icon="el-icon-mobile" type="text"
                     @click="showMobile = true"
          ></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('formDesign.PC')" placement="bottom-start">
          <el-button icon="el-icon-monitor" type="text"
                     @click="showMobile = false"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="work-form">
        <div :class="{'mobile': showMobile, 'pc': !showMobile}">
          <div :class="{'bd': showMobile}">
            <div :class="{'form-content': showMobile}">
              <div class="form">
                <div class="tip" v-show="forms.length === 0 && !isStart">👈 {{ $t('formDesign.centerTips') }}</div>
                <draggable class="drag-from" :list="forms" group="form"
                           :options="{animation: 300, chosenClass:'choose', sort:true}"
                           @start="drag = true; selectFormItem = null" @end="drag = false"
                >
                  <div v-for="(item, index) in forms" :key="index" class="form-item" @click="selectItem(item)"
                       :style="getSelectedClass(item)"
                  >
                    <div class="form-header">
                      <p><span v-if="item.props.required">*</span>{{ item.title }}</p>
                      <div class="option">
                        <i class="el-icon-close" @click="del(index)"></i>
                      </div>
                      <FormDesignRender :config="item"/>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-board">
      <div class="tool-nav-r" v-if="selectFormItem!==null">
        <i :class="selectFormItem.icon" style="margin-right: 5px; font-size: medium"></i>
        <span>{{ selectFormItem.title }}</span>
      </div>
      <div v-if="selectFormItem===null || forms.length === 0" class="tip">
        {{ $t('formDesign.rightTips') }}
      </div>
      <div style="text-align:left; padding: 10px" v-else>
        <FormComponentConfig/>
      </div>
    </div>
    <WDialog clickClose closeFree width="800px" :showFooter="false" :border="false" :title="$t('formDesign.preview')"
             v-model="viewFormVisible">
      <FormRender ref="form" :forms="forms" v-model="formData"/>
    </WDialog>
    <div class="el-login-footer">
      <el-button type="primary" @click="next" round>{{$t('process.next')}}</el-button>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import FormRender from '@/views/common/form/FormRender'
import FormDesignRender from '@/views/admin/layout/form/FormDesignRender'
import FormComponentConfig from '@/views/common/form/FormComponentConfig'
import {baseComponents} from '@/views/common/form/ComponentsConfigExport'
import WDialog from '@/components/common/WDialog'
import logo from '@/assets/logo/logo.png'

export default {
  name: 'FormDesign',
  components: {draggable, FormComponentConfig, FormDesignRender, FormRender, WDialog},
  data() {
    return {
      formData: {},
      libSelect: 0,
      viewFormVisible: false,
      isStart: false,
      showMobile: true,
      logo,
      baseComponents,
      select: null,
      drag: false
    }
  },
  computed: {
    forms() {
      return this.$store.state.design.formItems
    },
    selectFormItem: {
      get() {
        return this.$store.state.selectFormItem
      },
      set(val) {
        this.$store.state.selectFormItem = val
      }
    },
    nodeMap() {
      return this.$store.state.nodeMap
    }
  },
  methods: {
    next() {
      this.$emit('next');
    },
    getId() {
      return 'field' + (Math.floor(Math.random() * (99999 - 10000)) + 10000).toString()
          + new Date().getTime().toString().substring(5)
    },
    del(index) {
      const tips = this.$t('common.popTips')
      const message = this.$t('formDesign.delMsg')
      this.$confirm(message, tips, {
        confirmButtonText: this.$t('common.ok'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        if (this.forms[index].id === this.selectFormItem.id) {
          this.selectFormItem = null
        }
        if (this.forms[index].name === 'SpanLayout') {
          //删除的是分栏则遍历删除分栏内所有子组件
          this.forms[index].props.items.forEach(item => {
            this.removeFormItemAbout(item)
          })
          this.forms[index].props.items.length = 0
        } else {
          this.removeFormItemAbout(this.forms[index])
        }
        this.forms.splice(index, 1)
      })
    },
    async removeFormItemAbout(item) {
      this.nodeMap.forEach(node => {
        //搜寻条件，进行移除
        if (node.type === 'CONDITION') {
          node.props.groups.forEach(group => {
            let i = group.cids.remove(item.id)
            if (i > -1) {
              //从子条件移除
              group.conditions.splice(i, 1)
            }
          })
        }
        //搜寻权限，进行移除
        if (node.type === 'ROOT' || node.type === 'APPROVAL' || node.type === 'CC') {
          node.props.formPerms.removeByKey('id', item.id)
          if (node.props.formUser === item.id) {
            node.props.formUser = ''
          }
        }
      })
    },
    clone(obj) {
      obj.id = this.getId()
      return JSON.parse(JSON.stringify(obj))
    },
    viewForms() {
      this.viewFormVisible = true
    },
    selectItem(item) {
      this.selectFormItem = item
    },
    getSelectedClass(cp) {
      return this.selectFormItem && this.selectFormItem.id === cp.id ?
          'border-left: 4px solid #409eff' : ''
    },
    validateItem(err, titleSet, item) {
      if (titleSet.has(item.title) && item.name !== 'SpanLayout') {
        err.push(this.$t('formDesign.errorTips.nameRepeat', {title: item.title}))
      }
      titleSet.add(item.title)
      if (item.name === 'SelectInput' || item.name === 'MultipleSelect') {
        if (item.props.options.length === 0) {
          err.push(this.$t('formDesign.errorTips.noSetOptions', {title: item.title}))
        }
      } else if (item.name === 'TableList') {
        if (item.props.columns.length === 0) {
          err.push(`error`)
        }
      } else if (item.name === 'SpanLayout') {
        if (item.props.items.length === 0) {
          err.push(this.$t('formDesign.errorTips.noAdd'))
        } else {
          item.props.items.forEach(sub => this.validateItem(err, titleSet, sub))
        }
      }
    },
    validate() {
      let err = []
      if (this.forms.length > 0) {
        let titleSet = new Set()
        this.forms.forEach(item => {
          //主要校验表格及分栏/选择器/表单名称/是否设置
          this.validateItem(err, titleSet, item)
        })
      } else {
        err.push(this.$t('formDesign.errorTips.form'))
      }
      return err
    }
  }
}
</script>

<style lang="scss" scoped>
body, html {
  margin: 0;
  padding: 0;
  background: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}
.el-login-footer {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}

input, textarea {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

.editor-tabs {
  background: #121315;

  .el-tabs__header {
    margin: 0;
    border-bottom-color: #121315;

    .el-tabs__nav {
      border-color: #121315;
    }
  }

  .el-tabs__item {
    height: 32px;
    line-height: 32px;
    color: #888a8e;
    border-left: 1px solid #121315 !important;
    background: #363636;
    margin-right: 5px;
    user-select: none;
  }

  .el-tabs__item.is-active {
    background: #1e1e1e;
    border-bottom-color: #1e1e1e !important;
    color: #fff;
  }

  .el-icon-edit {
    color: #f1fa8c;
  }

  .el-icon-document {
    color: #a95812;
  }
}

// home
.right-scrollbar {
  .el-scrollbar__view {
    padding: 12px 18px 15px 15px;
  }
}

.left-scrollbar .el-scrollbar__wrap {
  box-sizing: border-box;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}

.center-tabs {
  .el-tabs__header {
    margin-bottom: 0 !important;
  }

  .el-tabs__item {
    width: 50%;
    text-align: center;
  }

  .el-tabs__nav {
    width: 100%;
  }
}

.reg-item {
  padding: 12px 6px;
  background: #f8f8f8;
  position: relative;
  border-radius: 4px;

  .close-btn {
    position: absolute;
    right: -6px;
    top: -6px;
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    color: #fff;
    text-align: center;
    z-index: 1;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      background: rgba(210, 23, 23, 0.5)
    }
  }

  & + .reg-item {
    margin-top: 18px;
  }
}

.action-bar {
  & .el-button + .el-button {
    margin-left: 15px;
  }

  & i {
    font-size: 20px;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
}

.custom-tree-node {
  width: 100%;
  font-size: 14px;

  .node-operation {
    float: right;
  }

  i[class*="el-icon"] + i[class*="el-icon"] {
    margin-left: 6px;
  }

  .el-icon-plus {
    color: #409EFF;
  }

  .el-icon-delete {
    color: #157a0c;
  }
}

.left-scrollbar .el-scrollbar__view {
  overflow-x: hidden;
}

.el-rate {
  display: inline-block;
  vertical-align: text-top;
}

.el-upload__tip {
  line-height: 1.2;
}

$selectedColor: #f6f7ff;
$lighterBlue: #409EFF;

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.components-list {
  padding: 8px;
  box-sizing: border-box;
  height: 100%;

  .components-item {
    display: inline-block;
    width: 48%;
    margin: 1%;
    transition: transform 0ms !important;
  }
}

.components-draggable {
  padding-bottom: 20px;
}

.components-title {
  font-size: 14px;
  color: #222;
  margin: 6px 2px;

  .svg-icon {
    color: #666;
    font-size: 18px;
  }
}

.components-body {
  padding: 8px 10px;
  background: $selectedColor;
  font-size: 12px;
  cursor: move;
  border: 1px dashed $selectedColor;
  border-radius: 3px;

  .svg-icon {
    color: #777;
    font-size: 15px;
  }

  &:hover {
    border: 1px dashed #787be8;
    color: #787be8;

    .svg-icon {
      color: #787be8;
    }
  }
}

.left-board {
  width: 260px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
}

.left-scrollbar {
  height: calc(100vh - 42px);
  overflow: hidden;
  background: #fff;
}

.center-board {
  height: 100vh;
  width: auto;
  margin: 0 350px 0 260px;
  box-sizing: border-box;
  background: #fff;
}


.action-bar {
  position: relative;
  height: 42px;
  text-align: right;
  padding: 0 15px;
  box-sizing: border-box;;
  border: 1px solid #f1e8e8;
  border-top: none;
  border-left: none;

  .delete-btn {
    color: #F56C6C;
  }
}

.work-form {
  margin: 0 auto;
  height: calc(100vh - 38px);
  overflow-y: auto;
  background: rgb(245, 246, 246);
  border-left: 1px solid rgb(235, 236, 238);
  border-right: 1px solid rgb(235, 236, 238);

  .pc {
    margin-top: 4%;

    .drag-from {
      height: calc(100vh - 190px);
      background-color: rgb(245, 246, 246);

      .form-item, li {
        cursor: grab;
        background: #ffffff;
        padding: 10px;
        border: 1px solid #ebecee;
        margin: 5px 0;
      }
    }
  }

  .mobile {
    margin-left: auto;
    margin-right: auto;
    width: 360px;
    max-height: 640px;
    margin-top: 4%;
    border-radius: 24px;
    box-shadow: 0 8px 40px 0 rgba(17, 31, 44, 0.12);

    .bd {
      border: 1px solid rgba(17, 31, 44, 0.08);
      border-radius: 24px;
      padding: 10px 10px;
      background-color: #ffffff;

      .form-content {
        padding: 3px 2px;
        border-radius: 14px;
        background-color: #f2f4f5;

        .drag-from {
          width: 100%;
          height: calc(100vh - 190px);
          min-height: 200px;
          max-height: 600px;
        }

        .form {
          overflow-y: auto;
          width: 100%;
          display: inline-block;
          max-height: 640px;

          .form-item, li {
            border: 1px solid #ffffff;
            list-style: none;
            background: #ffffff;
            padding: 10px;
            margin: 5px 0;
            cursor: grab;
          }
        }
      }
    }
  }

  .tip {
    width: 65%;
    max-width: 400px;
    padding: 35px 20px;
    border-radius: 10px;
    border: 1px dashed rgba(25, 31, 37, 0.12);
    margin: 50px auto 0;
    text-align: center;
    font-size: 14px;
    color: rgb(122, 122, 122);
    z-index: 9999;

    &:hover {
      border: 1px dashed #ccb1ea;
    }
  }
}

.form-header {
  font-size: small;
  color: #818181;
  text-align: left;
  position: relative;
  background-color: #fff;

  p {
    position: relative;
    margin: 0 0 10px 0;

    span {
      position: absolute;
      left: -8px;
      top: 3px;
      color: rgb(217, 0, 19);
    }
  }

  .option {
    position: absolute;
    top: -10px;
    right: -10px;

    i {
      font-size: large;
      cursor: pointer;
      color: #8c8c8c;
      padding: 5px;

      &:hover {
        color: #f56c6c;
      }
    }
  }
}

.logo-wrapper {
  position: relative;
  height: 42px;
  background: #fff;
  border-bottom: 1px solid #f1e8e8;
  box-sizing: border-box;
}

.logo {
  position: absolute;
  left: 12px;
  top: 6px;
  line-height: 30px;
  color: #00afff;
  font-weight: 600;
  font-size: 17px;
  white-space: nowrap;

  > img {
    width: 30px;
    height: 30px;
    vertical-align: top;
  }

  .github {
    display: inline-block;
    vertical-align: sub;
    margin-left: 15px;

    > img {
      height: 22px;
    }
  }
}


.drawing-item {
  position: relative;
  cursor: move;

  &.unfocus-bordered:not(.activeFromItem) > div:first-child {
    border: 1px dashed #ccc;
  }

  .el-form-item {
    padding: 12px 10px;
  }
}

.drawing-row-item {
  position: relative;
  cursor: move;
  box-sizing: border-box;
  border: 1px dashed #ccc;
  border-radius: 3px;
  padding: 0 2px;
  margin-bottom: 15px;

  .drawing-row-item {
    margin-bottom: 2px;
  }

  .el-col {
    margin-top: 22px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .drag-wrapper {
    min-height: 80px;
  }

  &.active-from-item {
    border: 1px dashed $lighterBlue;
  }

  .component-name {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    color: #bbb;
    display: inline-block;
    padding: 0 6px;
  }
}

.drawing-item, .drawing-row-item {
  &:hover {
    & > .el-form-item {
      background: $selectedColor;
      border-radius: 6px;
    }

    & > .drawing-item-copy, & > .drawing-item-delete {
      display: initial;
    }
  }

  & > .drawing-item-copy, & > .drawing-item-delete {
    display: none;
    position: absolute;
    top: -10px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    border: 1px solid;
    cursor: pointer;
    z-index: 1;
  }

  & > .drawing-item-copy {
    right: 56px;
    border-color: $lighterBlue;
    color: $lighterBlue;
    background: #fff;

    &:hover {
      background: $lighterBlue;
      color: #fff;
    }
  }

  & > .drawing-item-delete {
    right: 24px;
    border-color: #F56C6C;
    color: #F56C6C;
    background: #fff;

    &:hover {
      background: #F56C6C;
      color: #fff;
    }
  }
}


.right-board {
  width: 350px;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 3px;
  background: #fff;
  height: 100vh;

  .tool-nav-r {
    text-align: left;
    font-size: small;
    border-left: 1px solid #ebecee;
    padding: 10px 20px;
    background: #fafafb;
    border-bottom: 1px solid #ebecee;
  }

  .tip {
    margin-top: 200px;
    text-align: center;
    font-size: 14px;
    color: #9a9a9a;
  }
}
</style>
