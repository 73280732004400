<template>
  <el-form-item :label="$t('formConfig.placeholderLabel')">
    <el-input size="small" v-model="value.placeholder" :placeholder="$t('formConfig.placeholderValue')"  maxlength="15"/>
  </el-form-item>
</template>

<script>
export default {
  name: "TextInput",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
