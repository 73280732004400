<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input size="small" v-model="value.placeholder" :placeholder="$t('formConfig.placeholderValue')"  maxlength="15"/>
    </el-form-item>
    <el-form label-position="top">
      <el-form-item :label="$t('formConfig.select.label')" class="options">
        <div slot="label" class="option-item-label">
          <span>{{ $t('formConfig.select.label') }}</span>
          <el-button icon="el-icon-plus" type="text" size="mini"
                     @click="value.options.push($t('formConfig.select.addValue'))">
            {{ $t('formConfig.select.addLabel') }}
          </el-button>
        </div>
        <draggable :list="value.options" group="option" handler=".el-icon-rank" :options="dragOption">
          <div v-for="(op, index) in value.options" :key="index" class="option-item">
            <i class="el-icon-rank"></i>
            <el-input v-model="value.options[index]" size="medium" :placeholder="$t('formConfig.select.placeholder')"
                      clearable>
              <el-button icon="el-icon-delete" slot="append" type="danger" size="medium"
                         @click="remove(index)"></el-button>
            </el-input>
          </div>
        </draggable>

      </el-form-item>
    </el-form>
    <!--    <el-form-item label="选项展开">-->
    <!--      <el-switch v-model="value.expanding"></el-switch>-->
    <!--    </el-form-item>-->
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SelectInputConfig",
  components: {draggable},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      dragOption: {
        animation: 300,
        sort: true
      }
    }
  },
  methods: {
    remove(index) {
      if (this.value.options.length > 1) {
        this.value.options.splice(index, 1);
      } else {
        this.$message.warning("选择框至少有一个选项")
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .options {
  .el-form-item__label {
    display: block;
    width: 100%;
    text-align: left;
  }

  .el-icon-rank {
    padding-right: 5px;
    cursor: move;
  }

  .option-item {
    .el-input {
      width: 250px;
      float: right;
    }
  }
}

.option-item-label {
  height: 30px;
  line-height: 30px;

  button {
    float: right;
  }
}

/*/deep/ .el-form-item {
  margin-bottom: 10px;

  .el-form-item__label {
    padding: 0;
  }
  .options{
    .el-icon-rank{
      cursor: move;
    }
  }
}*/
</style>
