<template>
  <div>
    <el-form-item :label="$t('formConfig.placeholderLabel')">
      <el-input size="small" v-model="value.placeholder" :placeholder="$t('formConfig.placeholderValue')"  maxlength="15"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.upload.numLimitLabel')">
      <tip slot="label" :content="$t('formConfig.upload.imageNumLimitTips')">{{
          $t('formConfig.upload.numLimitLabel')
        }}
      </tip>
      <el-input-number class="max-fill" controls-position="right" :precision="0" size="small" v-model="value.maxNumber"
                       :placeholder="$t('formConfig.upload.imageNumLimitPlaceholder')"/>
    </el-form-item>
    <el-form-item :label="$t('formConfig.upload.sizeLimitLabel')">
      <tip slot="label" :content="$t('formConfig.upload.imageSizeLimitTips')">{{
          $t('formConfig.upload.sizeLimitLabel')
        }}
      </tip>
      <el-input-number class="max-fill" controls-position="right" :precision="1" size="small" v-model="value.maxSize"
                       :placeholder="$t('formConfig.upload.imageSizeLimitPlaceholder')"/>
    </el-form-item>
    <!--    <el-form-item label="图片压缩">-->
    <!--      <el-switch v-model="value.enableZip"></el-switch>-->
    <!--    </el-form-item>-->
  </div>
</template>

<script>
export default {
  name: "ImageUploadConfig",
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label {
  padding: 0 12px 0 0;
}
</style>
