import request from '@/api/request.js'


// 搜索人员
export function getUserByName(param) {
    return request({
        url: '/admin/user/list',
        method: 'get',
        params: param
    })
}

export default {getUserByName}
