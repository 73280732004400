<template>
  <WDialog :border="false" closeFree width="600px" @ok="selectOk" :title="title" v-model="visible">
    <div class="picker">
      <div class="candidate" v-loading="loading">
        <div v-if="type !== 'role'">
          <el-input v-model="search" @input="searchUser" style="width: 95%;" size="small"
                    clearable :placeholder="$t('orgPicker.searchPlaceholder')" prefix-icon="el-icon-search"
          />
          <div v-show="!showUsers">
            <!--            <ellipsis hoverTip style="height: 18px; color: #8c8c8c; margin: 5px 0 0" :row="1" :content="deptStackStr">-->
            <!--              <i slot="pre" class="el-icon-office-building"></i>-->
            <!--            </ellipsis>-->
            <div style="margin-top: 5px">
              <el-checkbox v-model="checkAll" @change="handleCheckAllChange" :disabled="!multiple||disableAll">
                {{ $t('orgPicker.selectAll') }}
              </el-checkbox>
              <span v-show="deptStack.length > 0" class="top-dept" style="color: #409EFF"
                    @click="beforeNode">{{ $t('orgPicker.back') }}</span>
            </div>
          </div>
        </div>
        <div class="role-header" v-else>
          <div>系统角色</div>
        </div>
        <div class="org-items" :style="type === 'role' ? 'height: 350px':''">
          <el-empty :image-size="100" :description="$t('orgPicker.leftEmptyTips')" v-show="orgs.length === 0"/>
          <div v-for="(org, index) in orgs" :key="index" :class="orgItemClass(org)" @click="selectChange(org)">
            <el-checkbox v-show="!disableDept(org)" v-model="org.selected" :disabled="disableDept(org)"
                         @change="selectChange(org)"
            ></el-checkbox>
            <div v-if="org.type === 'dept'">
              <i class="el-icon-folder-opened"></i>
              <span class="name">{{ org.name }}</span>
              <span @click.stop="nextNode(org)" style="color: #409EFF"
                    :class="`next-dept${org.selected ? '-disable':''}`"
              >
               <i class="iconfont icon-map-site"></i>{{ $t('orgPicker.next') }}
              </span>
            </div>
            <div v-else-if="org.type === 'user'" style="display: flex; align-items: center">
              <span
                  v-if="org.avatar!==undefined||org.avatar!==null"
                  style="width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;border: #409EFF solid 1px;
                 border-radius: 30px;padding: 2px;font-size: 12px;background: #409EFF;color: #FFFFFF"
              >
                {{ org.name.substring(org.name.length - 2, org.name.length) }}
              </span>
              <span v-else class="avatar">{{ getShortName(org.name) }}</span>
              <span class="name">{{ org.name }}</span>
            </div>
            <div style="display: inline-block" v-else>
              <i class="iconfont icon-bumen"></i>
              <span class="name">{{ org.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="selected">
        <div class="count">
          <span>{{ $t('orgPicker.leftTips', {data: select.length}) }}</span>
          <span @click="clearSelected">{{ $t('orgPicker.clear') }}</span>
        </div>
        <div class="org-items" style="height: 350px;">
          <el-empty :image-size="100" :description="$t('orgPicker.rightEmptyTips')" v-show="select.length === 0"/>
          <div v-for="(org, index) in select" :key="index" :class="orgItemClass(org)">
            <div v-if="org.type === 'dept'">
              <i class="el-icon-folder-opened"></i>
              <span style="position: static" class="name">{{ org.name }}</span>
            </div>
            <div v-else-if="org.type === 'user'" style="display: flex; align-items: center">
              <span
                  v-if="org.avatar!==undefined||org.avatar!==null"
                  style="width: 32px;height: 32px;display: flex;align-items: center;justify-content: center;border: #409EFF solid 1px;
                 border-radius: 30px;padding: 2px;font-size: 12px;background: #409EFF;color: #FFFFFF"
              >
                {{ org.name.substring(org.name.length - 2, org.name.length) }}
              </span>
              <span v-else class="avatar">{{ getShortName(org.name) }}</span>
              <span class="name">{{ org.name }}</span>
            </div>
            <div v-else>
              <i class="iconfont icon-bumen"></i>
              <span class="name">{{ org.name }}</span>
            </div>
            <i class="el-icon-close" @click="noSelected(index)"></i>
          </div>
        </div>
      </div>
    </div>
  </WDialog>
</template>
<script>
import WDialog from './WDialog'
import Ellipsis from './Ellipsis'
import {getUserByName} from '@/api/org'

export default {
  name: 'OrgPicker',
  components: {WDialog, Ellipsis},
  props: {
    title: {
      default: '请选择',
      type: String
    },
    type: {
      default: 'org', //org选择部门/人员  user-选人  dept-选部门 role-选角色
      type: String
    },
    multiple: { //是否多选
      default: false,
      type: Boolean
    },
    selected: {
      default: () => {
        return []
      },
      type: Array
    },
    disable: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      checkAll: false,
      nowDeptId: null,
      isIndeterminate: false,
      searchUsers: [],
      nodes: [],
      select: [],
      search: '',
      deptStack: [],
      disableAll: true
    }
  },
  computed: {
    deptStackStr() {
      return String(this.deptStack.map(v => v.name)).replaceAll(',', ' > ')
    },
    orgs() {
      return !this.search || this.search.trim() === '' ? this.nodes : this.searchUsers
    },
    showUsers() {
      return this.search || this.search.trim() !== ''
    }
  },
  methods: {
    show() {
      this.init()
      this.getOrgList(true)
      this.visible = true
    },
    orgItemClass(org) {
      return {
        'org-item': true,
        'org-dept-item': org.type === 'dept',
        'org-user-item': org.type === 'user',
        'org-role-item': org.type === 'role'
      }
    },
    disableDept(node) {
      let disable = (this.type === 'user' && 'dept' === node.type) || node.type === this.disable
      if (!disable) {
        this.disableAll = disable
      }
      return disable
    },
    getOrgList(init) {
      this.loading = true
      getUserByName().then(rsp => {
        this.loading = false
        this.nodes = rsp.data
        if (init) {
          this.select = Object.assign([], this.selected)
        }
        this.selectToLeft()
        this.disableAll = true
      })
    },
    getShortName(name) {
      if (name) {
        return name.length > 2 ? name.substring(1, 3) : name
      }
      return '**'
    },
    searchUser() {
      let userName = this.search.trim()
      this.searchUsers = []
      this.loading = true
      getUserByName({key: userName}).then(rsp => {
        this.loading = false
        this.searchUsers = rsp.data
        this.selectToLeft()
      })
    },
    selectToLeft() {
      let nodes = this.search.trim() === '' ? this.nodes : this.searchUsers
      nodes.forEach(node => {
        for (let i = 0; i < this.select.length; i++) {
          if (this.select[i].id === node.id) {
            node.selected = true
            break
          } else {
            node.selected = false
          }
        }
      })
    },
    selectChange(node) {
      if (node.selected) {
        this.checkAll = false
        for (let i = 0; i < this.select.length; i++) {
          if (this.select[i].id === node.id) {
            this.select.splice(i, 1)
            break
          }
        }
        node.selected = false
      } else if (!this.disableDept(node)) {
        node.selected = true
        let nodes = this.search.trim() === '' ? this.nodes : this.searchUsers
        if (!this.multiple) {
          nodes.forEach(nd => {
            if (node.id !== nd.id) {
              nd.selected = false
            }
          })
        }
        if (node.type === 'dept') {
          if (!this.multiple) {
            this.select = [node]
          } else {
            this.select.unshift(node)
          }
        } else {
          if (!this.multiple) {
            this.select = [node]
          } else {
            this.select.push(node)
          }
        }
      }
    },
    noSelected(index) {
      let nodes = this.nodes
      for (let f = 0; f < 2; f++) {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === this.select[index].id) {
            nodes[i].selected = false
            this.checkAll = false
            break
          }
        }
        nodes = this.searchUsers
      }
      this.select.splice(index, 1)
    },
    handleCheckAllChange() {
      this.nodes.forEach(node => {
        if (this.checkAll) {
          if (!node.selected && !this.disableDept(node)) {
            node.selected = true
            this.select.push(node)
          }
        } else {
          node.selected = false
          for (let i = 0; i < this.select.length; i++) {
            if (this.select[i].id === node.id) {
              this.select.splice(i, 1)
              break
            }
          }
        }
      })
    },
    nextNode(node) {
      this.checkAll = false
      this.nowDeptId = node.id
      this.deptStack.push(node)
      this.getOrgList()
    },
    beforeNode() {
      this.checkAll = false
      if (this.deptStack.length === 0) {
        return
      }
      if (this.deptStack.length < 2) {
        this.nowDeptId = null
      } else {
        this.nowDeptId = this.deptStack[this.deptStack.length - 2].id
      }
      this.deptStack.splice(this.deptStack.length - 1, 1)
      this.getOrgList()
    },
    recover() {
      this.select = []
      this.nodes.forEach(nd => nd.selected = false)
    },
    selectOk() {
      this.$emit('ok', Object.assign([], this.select.map(v => {
        v.avatar = undefined
        return v
      })))
      this.visible = false
      this.recover()
    },
    clearSelected() {
      this.$confirm('您确定要清空已选中的项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.recover()
      })
    },
    close() {
      this.$emit('close')
      this.recover()
    },
    init() {
      this.checkAll = false
      this.nowDeptId = null
      this.deptStack = []
      this.nodes = []
      this.select = Object.assign([], this.selected)
      this.selectToLeft()
    }
  }
}
</script>

<style lang="scss" scoped>
.candidate, .selected {
  position: absolute;
  display: inline-block;
  width: 278px;
  height: 400px;
  border: 1px solid #e8e8e8;
}

.el-dialog__body {
  padding: 10px 20px;
}

.picker {
  height: 402px;
  position: relative;
  text-align: left;

  .candidate {
    left: 0;
    top: 0;

    .role-header {
      padding: 10px !important;
      margin-bottom: 5px;
      border-bottom: 1px solid #e8e8e8;
    }

    .top-dept {
      margin-left: 20px;
      cursor: pointer;
    }

    .next-dept {
      float: right;
      cursor: pointer;
    }

    .next-dept-disable {
      float: right;
      color: #8c8c8c;
      cursor: not-allowed;
    }

    & > div:first-child {
      padding: 5px 10px;
    }
  }

  .selected {
    right: 0;
    top: 0;
  }

  .org-items {
    overflow-y: auto;
    height: 310px;

    .el-icon-close {
      position: absolute;
      right: 5px;
      cursor: pointer;
      font-size: larger;
    }

    .org-dept-item {
      padding: 10px 5px;

      & > div {
        display: inline-block;

        & > span:last-child {
          position: absolute;
          right: 5px;
        }
      }
    }

    .org-role-item {
      display: flex;
      align-items: center;
      padding: 10px 5px;
    }

    .org-user-item {
      display: flex;
      align-items: center;
      padding: 5px;

      & > div {
        display: inline-block;
      }

      .avatar {
        width: 35px;
        text-align: center;
        line-height: 35px;
        color: white;
        border-radius: 50%;
      }
    }

    .org-item {
      margin: 0 5px;
      border-radius: 5px;
      position: relative;

      .el-checkbox {
        margin-right: 10px;
      }

      .name {
        margin-left: 5px;
      }

      &:hover {
        background: #f1f1f1;
      }
    }
  }
}

.selected {
  border-left: none;

  .count {
    width: calc(278px - 20px);
    padding: 10px;
    display: inline-block;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 5px;

    & > span:nth-child(2) {
      float: right;
      color: #c75450;
      cursor: pointer;
    }
  }
}

.disabled {
  cursor: not-allowed !important;
  color: #8c8c8c !important;
}

::-webkit-scrollbar {
  float: right;
  width: 4px;
  height: 4px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #efefef;
}
</style>
